<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="detail.project_name"
    class="px-5 py-3"
    v-if="detail"
  >
    <base-detail-action-button
      :permissions="permissions"
      :basePath="basePath"
      :id="detail.uuid"
      v-if="detail.uuid"
      @deleteAction="deleteAction"
    >
    </base-detail-action-button>
    <v-row>
      <v-col cols="6" class="border--right">
        <v-row>
          <v-col cols="6">
            {{ $t('project.projectName') }}
          </v-col>
          <v-col cols="6">
            {{ detail.project_name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.projectDesc') }}
          </v-col>
          <v-col cols="6">
            {{ shortString(stripHtml(detail.project_desc), 200) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6"> Komisi </v-col>
          <v-col cols="6"> {{ detail.commission }}% </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.developerName') }}
          </v-col>
          <v-col cols="6">
            {{ detail.developer_name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.constructionStatus') }}
          </v-col>
          <v-col cols="6">
            {{ detail.construction_status }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.estFinishYear') }}
          </v-col>
          <v-col cols="6">
            {{ detail.estimated_finish_year }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.address') }}
          </v-col>
          <v-col cols="6">
            {{ detail.address }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.village') }}
          </v-col>
          <v-col cols="6">
            {{ detail.village }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.propertyType') }}
          </v-col>
          <v-col cols="6">
            {{ detail.property_type }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.subsidized') }}
          </v-col>
          <v-col cols="6">
            {{ subsidized }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            {{ $t('project.certificate') }}
          </v-col>
          <v-col cols="6">
            {{ detail.certificate }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.waterSource') }}
          </v-col>
          <v-col cols="6">
            {{ detail.water_source }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.minPrice') }}
          </v-col>
          <v-col cols="6">
            {{ $n(detail.min_price, 'currency', 'id-ID') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.maxPrice') }}
          </v-col>
          <v-col cols="6">
            {{ $n(detail.max_price, 'currency', 'id-ID') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.youtubeUrl') }}
          </v-col>
          <v-col cols="6">
            {{ detail.youtube_url }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.brochureUrl') }}
          </v-col>
          <v-col cols="6">
            {{ detail.brochure_url }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.updatedBy') }}
          </v-col>
          <v-col cols="6">
            {{ detail.updated_by }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.impression') }}
          </v-col>
          <v-col cols="6">
            {{ detail.impression_amount }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.visit') }}
          </v-col>
          <v-col cols="6">
            {{ detail.visit_amount }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.lead') }}
          </v-col>
          <v-col cols="6">
            {{ detail.lead_amount }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions'],
  computed: {
    ...mapState({
      basePath: (state) => state.project.basePath,
      subsidyList: (state) => state.project.form.subsidyList,
    }),
    subsidized() {
      let subsidized = this.subsidyList.find((item) => {
        return item.id === this.detail.subsidized;
      });
      return subsidized ? subsidized.name : '';
    },
  },
  methods: {
    async deleteAction(uuid) {
      console.log('DELETE PROJECT UUID: ', uuid);
      try {
        await this.$store.dispatch('project/delete', uuid);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('project.delete.successMsg'),
          'success',
        );
        await this.$router.replace('/project-management/projects');
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
